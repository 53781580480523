export async function sendPublishingFormData(formData) {
    try {
        const response = await fetch('//admin.applife.io/publishingForm', {
            method: "POST",
            body: formData
        });

        if (!response.ok) throw await response.json();

        return response;
    } catch (error) {
        console.log('ERROR', error);

        return await Promise.reject(error);
    }
}

export async function sendSupportFormData(formData) {
  try {
      const response = await fetch('//admin.applife.io/supportForm', {
          method: "POST",
          body: formData
      });

      if (!response.ok) throw await response.json();

      return response;
  } catch (error) {
      console.log('ERROR', error);

      return await Promise.reject(error);
  }
}

export async function sendCareersFormData(formData) {
  try {
    const response = await fetch('//admin.applife.io/appLifeCareersForm', {
      method: "POST",
      body: formData
    });

    if (!response.ok) throw await response.json();

    return response;
  } catch (error) {
    console.log('ERROR', error);

    return await Promise.reject(error);
  }
}

export async function sendPartnershipFormData(formData) {
  try {
    const response = await fetch('//admin.applife.io/appLifePartnershipForm', {
      method: "POST",
      body: formData
    });

    if (!response.ok) throw await response.json();

    return response;
  } catch (error) {
    console.log('ERROR', error);

    return await Promise.reject(error);
  }
}


