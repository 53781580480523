import React from "react";
import styles from './styles.module.scss';
import { Input } from "../input";
import { Selector } from "../selector";
import { FileUpload } from "../file-upload";
import { Checkbox } from "../checkbox";
import { Loading } from "../loading";
import { sendPublishingFormData } from "../../request-service";

export function PublishingForm() {
    const stageOptions = [
        {
            value: "closed beta",
            label: "Closed Beta",
        },
        {
            value: "public beta",
            label: "Public Beta",
        }, {
            value: "production",
            label: "Production",
        }
    ];

    const defaultFormData = {
        projectName: '',
        projectDescription: '',
        gameGenre: '',
        gameSettings: '',
        developmentStage: '',
        gameLink: '',
        videoPitch: '',
        companyName: '',
        contactPerson: '',
        email: '',
        location: '',
        companySize: '',
        portfolio: '',
        files: [],
        isIOS: false,
        isAndroid: false,
    };

    const [formData, setFormData] = React.useState(defaultFormData);
    const [loading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    async function handleFormSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const uploadFormData = new FormData();
        const { files, ...restFormData } = formData;

        for (const key in restFormData) {
            if (restFormData.hasOwnProperty(key)) {
                uploadFormData.append(key, restFormData[key])
            }
        }

        if (files) {
            files.forEach((file) => {
                uploadFormData.append('file', file);
            });
        }

        try {
            await sendPublishingFormData(uploadFormData);

            setFormData(defaultFormData);
            setLoading(false);
            setSuccessMessage('Your project data was successfully sent.');
        } catch (error) {
            setLoading(false);
            setErrorMessage('Something went wrong, please try again later');
        }
    }

    function getFileExtension(name){
        const nameParts = name.split('.');
        if (nameParts.length > 0)
            return nameParts[nameParts.length - 1];
        else
            return '';
    }

    function filterFiles(files){
        var validExt = ['pdf', 'odt', 'ppt', 'pptx', 'key', 'doc', 'docx', 'rtf', 'wpd', 'apk'];
        var res = [];
        for(var i=0;i<files.length;i++){
            var ext = getFileExtension(files[i].name);
            if (validExt.includes(ext)){
                res.push(files[i]);
          }
        }

        return res;
    }
    function handleFormChange({ target }) {
        let value = target.value;

        if (target.files) {
            value = formData.files;
            Array.prototype.push.apply(value, filterFiles(target.files));
            value = value.slice(0, 3);
        }

        setFormData({
            ...formData,
            [target.name]: value
        });
    }

    function handleFormCheckboxes(event) {
        event.stopPropagation();

        setFormData({
            ...formData,
            [event.target.id]: event.target.checked
        });
    }

    function handleFormSelector(data, event) {
        setFormData({
            ...formData,
            [event.name]: data.value
        });
    }

    function handleFileUploadFileRemove(file) {
        const filesStateCopy = [...formData.files];
        const existingIndex = filesStateCopy.indexOf(file);

        if (existingIndex > -1) {
            filesStateCopy.splice(existingIndex, 1);

            setFormData({
                ...formData,
                files: filesStateCopy
            })
        }
    }

    function handleMessageClose() {
        setErrorMessage('');
        setSuccessMessage('');
    }

    return (
        <div className={styles.publishing}>
            <Loading
                isLoading={loading}
                errorMessage={errorMessage}
                successMessage={successMessage}
                onMessageClose={handleMessageClose}
            />

            <form
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
            >
                <div>
                    <Input
                        label="Project name"
                        value={formData.projectName}
                        name="projectName"
                        id="projectName"
                    />
                    <Input
                        label="Project description"
                        value={formData.projectDescription}
                        name="projectDescription"
                        id="projectDescription"
                        isTextarea
                    />
                    <Input
                        label="Game genre"
                        value={formData.gameGenre}
                        name="gameGenre"
                        id="gameGenre"
                    />
                    <Input
                        label="Game settings"
                        value={formData.gameSettings}
                        name="gameSettings"
                        id="gameSettings"
                    />

                    <Checkbox
                        name="isIOS"
                        id="isIOS"
                        label="iOS"
                        checked={formData.isIOS}
                        onChange={handleFormCheckboxes}
                    />

                    <Checkbox
                        name="isAndroid"
                        id="isAndroid"
                        label="Android"
                        checked={formData.isAndroid}
                        onChange={handleFormCheckboxes}
                    />

                    <Selector
                        label="Development stage"
                        value={stageOptions.find(item => item.value === formData.developmentStage)}
                        name="developmentStage"
                        id="developmentStage"
                        options={stageOptions}
                        onChange={handleFormSelector}
                        isRequired={true}
                    />

                    <div className={styles.formTextBlock}>
                        <h3>Presentation and documentation</h3>
                        <p>
                            Send a detailed presentation about your project. This is a key of our evaluation and
                            selection process, so it's super important to make it high quality and informative.
                            Upload the files in one of the following formats: pdf, odt, ppt, pptx, key, doc, docx, rtf,
                            wpd, apk. You can upload up to 3 files.
                        </p>
                    </div>

                    <FileUpload
                        name='files'
                        files={formData.files}
                        onFileRemove={handleFileUploadFileRemove}
                    />

                    <Input
                        label="Game link"
                        value={formData.gameLink}
                        name="gameLink"
                        id="gameLink"
                    />
                    <Input
                        label="Video pitch"
                        value={formData.videoPitch}
                        name="videoPitch"
                        id="videoPitch"
                        isTextarea
                        placeholder="Send a video link showcasing of your project"
                    />

                </div>
                <div className={styles.rightForm}>
                    <h2 className={styles.formH2}>
                        Team details
                    </h2>

                    <Input
                        label="Team/company name"
                        value={formData.companyName}
                        name="companyName"
                        id="companyName"
                    />
                    <Input
                        label="Contact person"
                        value={formData.contactPerson}
                        name="contactPerson"
                        id="contactPerson"
                    />
                    <Input
                        label="Email"
                        value={formData.email}
                        name="email"
                        id="email"
                        type="email"
                    />
                    <Input
                        label="Where are you based"
                        value={formData.location}
                        name="location"
                        id="location"
                    />
                    <Input
                        label="Team/company size"
                        value={formData.companySize}
                        name="companySize"
                        id="companySize"
                    />

                    <Input
                        label="Portfolio"
                        value={formData.portfolio}
                        name="portfolio"
                        id="portfolio"
                        isTextarea
                        placeholder="Tell us about your game development experience and provide App store/Google play links"
                        rows={5}
                    />
                </div>

                <div className={styles.bottom}>
                    <div className="formSubmitButtonErrorsWrap contactFormButton">
                        <input
                            type="submit"
                            className="buttonWrap button button-green contactSubmitButton"
                            value="Send Project"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
