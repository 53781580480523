import React from "react";
import styles from './index.module.scss';

export const RadioInput = (props) => {
    return (
        <div className={styles.radioButton}>
            <input
                {...props}
                id={"radio_" + props.value}
                type="radio"
            />
            <label htmlFor={"radio_" + props.value}>
                {props.label}
            </label>
        </div>
    )
};