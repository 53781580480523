 import React from "react";
import styles from './styles.module.scss';
import { Input } from "../input";
import { Selector } from "../selector";
import { Loading } from "../loading";
import { RadioInput } from "../radio";
import { FileUpload } from "../file-upload";
import { sendSupportFormData } from "../../request-service";

export function ContactForm() {

    const iOS = /iP(ad|hone|od)/.test(navigator.userAgent);

    const defaultFormData = {
        game: '',
        name: '',
        email: '',
        message: '',
        platform: iOS ? 'ios' : 'android',
        files: []
    };

    const [games, setGames] = React.useState('');

    React.useEffect(() => {
        getGames();
        async function getGames() {
            const response = await fetch("https://admin.applife.io/applications?_sort=Order:DESC");
            var gms = await response.json();
            var results = [];
            gms.forEach(gm => {
                results.push({
                    label: gm.name,
                    value: gm.name
                });
            });
            setGames(results);
        }
    }, []);

    const [formData, setFormData] = React.useState(defaultFormData);

    const [loading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    async function handleFormSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const uploadFormData = new FormData();
        const { files, ...restFormData } = formData;

        for (const key in restFormData) {
            if (restFormData.hasOwnProperty(key)) {
                uploadFormData.append(key, restFormData[key])
            }
        }

        if (files) {
            files.forEach((file) => {
                uploadFormData.append('file', file);
            });
        }

        try {
            await sendSupportFormData(uploadFormData);

            setFormData(defaultFormData);
            setLoading(false);
            setSuccessMessage('Your message was successfully sent.');
        } catch (error) {
            setLoading(false);
            setErrorMessage('Something went wrong, please try again later');
        }
    }

    function handleFormChange({ target }) {
        let value = target.value;

        if (target.files) {
            value = formData.files;
            Array.prototype.push.apply(value, target.files);
        } 

        setFormData({
            ...formData,
            [target.name]: value
        });
    }

    function handleFormSelector(data, event) {
        setFormData({
            ...formData,
            game: data.value
        });
    }

    function handleMessageClose() {
        setErrorMessage('');
        setSuccessMessage('');
    }

    function handleFileUploadFileRemove(file) {
        const filesStateCopy = [...formData.files];
        const existingIndex = filesStateCopy.indexOf(file);

        if (existingIndex > -1) {
            filesStateCopy.splice(existingIndex, 1);

            setFormData({
                ...formData,
                files: filesStateCopy
            })
        }
    }

    function handleFormInputChange({ currentTarget }) {
        if (currentTarget) {

            let value = currentTarget.value;

            if (currentTarget.files) {
                value = [];
                Array.prototype.push.apply(value, currentTarget.files);
            }

            setFormData({
                ...formData,
                [currentTarget.name]: value
            })
        }
    }

    return (
        <div className={styles.contactsForm}>
            <Loading
                isLoading={loading}
                errorMessage={errorMessage}
                successMessage={successMessage}
                onMessageClose={handleMessageClose}
            />

            <form
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
            >
                <Selector
                    label="Game"
                    name="game"
                    id="game"
                    options={games}
                    onChange={handleFormSelector}
                    isRequired={true}
                />

                <div className={styles.separated}>
                    <div>
                        <Input
                            label="Name"
                            value={formData.name}
                            name="name"
                            id="name"
                        />
                    </div>

                    <div>
                        <Input
                            label="Email"
                            value={formData.email}
                            name="email"
                            id="email"
                            type="email"
                        />
                    </div>
                </div>

                <Input
                    label="Message"
                    value={formData.message}
                    name="message"
                    id="message"
                    isTextarea
                />

                <div className={styles.formLine} className={styles.formLineFlex}>
                    <RadioInput
                        name="platform"
                        onChange={handleFormInputChange}
                        value="ios"
                        checked={formData.platform === 'ios'}
                        label="iOS"
                    />

                    <RadioInput
                        name="platform"
                        onChange={handleFormInputChange}
                        value="android"
                        checked={formData.platform === 'android'}
                        label="Android"
                    />

                    <FileUpload
                        name='files'
                        files={formData.files}
                        onChange={handleFormInputChange}
                        onFileRemove={handleFileUploadFileRemove}
                    />
                </div>

                <div className={styles.bottom}>
                    <div className="formSubmitButtonErrorsWrap contactFormButton">
                        <input
                            type="submit"
                            className="buttonWrap button button-green contactSubmitButton"
                            value="Send Message"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
