import React from "react";
import styles from './index.module.scss';
import { PartnershipForm } from "./components/partnership-form";
import { PublishingForm } from "./components/publishing-form";
import { ContactForm } from "./components/contacts-form";
import { CareersForm } from "./components/careers-form";
import { Tabs } from "./components/tabs";

export function App() {
    const tabs = [
        {
            value: 'support',
            label: 'Customer support'
        },
        {
            value: 'publishing',
            label: 'Publishing'
        },
        {
            value: 'careers',
            label: 'Careers'
        },
        {
            value: 'partnership',
            label: 'Partnership'
        }
    ];

    const [currentTab, setCurrentTab] = React.useState(tabs[0]);

    // Outside access to change the current tab
    window['setCurrentTab'] = setCurrentTab;

    return (
        <div className={styles.forms}>
            {currentTab.value === tabs[0].value && (
                <h2 class="center-text small-bottom containers-header">Contact Us</h2>
            )}

            {currentTab.value === tabs[1].value && (
                <React.Fragment>
                    <h2 class="center-text small-bottom containers-header">Publishing</h2>

                    <div className={styles.headerDescription}>
                        We Are Always Open For Cooperation
                    </div>
                </React.Fragment>
            )}

            {currentTab.value === tabs[2].value && (
                <React.Fragment>
                    <h2 class="center-text small-bottom containers-header">Careers</h2>

                    <div className={styles.headerDescription}>
                      We Are Always Open For Cooperation
                    </div>
                </React.Fragment>
            )}

            {currentTab.value === tabs[3].value && (
                <React.Fragment>
                <h2 class="center-text small-bottom containers-header">Partnership</h2>

                  <div className={styles.headerDescription}>
                  We Are Always Open For Cooperation
                </div>
                </React.Fragment>
            )}

            <Tabs
                tabs={tabs}
                onClick={setCurrentTab}
                currentTab={currentTab}
            />

            {currentTab.value === tabs[0].value && (
                <ContactForm />
            )}

            {currentTab.value === tabs[1].value && (
                <PublishingForm />
            )}

            {currentTab.value === tabs[2].value && (
                <CareersForm />
            )}

            {currentTab.value === tabs[3].value && (
                <PartnershipForm />
            )}


        </div>
    )
}
