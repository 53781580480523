import React from "react";
import styles from './styles.module.scss';

export const FileUpload = ({
    files = [],
    onChange = () => undefined,
    onFileRemove = () => undefined,
    name='',
    ...props
}) => {
    function getFileExtension({name}){
        const nameParts = name.split('.');

        return nameParts[nameParts.length - 1];
    }

    return (
        <>
            <input
                {...props}
                id={'file_upload_' + name}
                type="file"
                multiple={true}
                accept="image/*,application/pdf,application/vnd.oasis.opendocument.text,application/vnd.ms-powerpoint,application/msword"
                onChange={onChange}
                className={styles.nativeInput}
                name={name}
            />
            <label
                className={styles.fileUpload}
                htmlFor={'file_upload_' + name}>
                Attach File
            </label>

            <div className={styles.filesForUpload}>
                {files.map((file) => (
                    <div
                        key={file.name}
                        className={styles.filePreview}
                    >
                        <span className={styles.extension}>{getFileExtension(file)}</span>
                        <button onClick={() => onFileRemove(file)}>&times;</button>
                    </div>
                ))}
            </div>
        </>
    )
};
