import React from "react";
import styles from './styles.module.scss';
import { Button } from "../button";

export function Tabs({
    tabs = [],
    currentTab = '',
    onClick = () => undefined
}) {
    function handleClick(tab) {
        onClick(tab);
    }

    return (
        <div className={styles.tabs}>
            {tabs.map(tab => (
                <Button
                    fullSize={false}
                    isRounded={true}
                    isDefault={tab.value !== currentTab.value}
                    label={tab.label}
                    onClick={() => handleClick(tab)}
                />
            ))}
        </div>
    )
}
