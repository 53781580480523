import React from "react";
import Select from "react-select";
import './index.scss'

export function Selector({
    id = '',
    value = {},
    onChange = () => undefined,
    name = "",
    isRequired = true,
    label = '',
    options = [],
    ...props
}) {
    return (
        <div className="formFieldWrap">
            <label className="field-title contactNameField" htmlFor="contactNameField">
                {label}: {isRequired && (<span>(required)</span>)}
            </label>

            <Select
                id={id}
                name={name}
                options={options}
                onChange={onChange}
                classNamePrefix="selector"
                isSearchable={false}
                blurInputOnSelect={false}
                {...props}
            />
        </div>
    )
}



