import React from "react";
import styles from './index.module.scss';

export function Checkbox({
    onChange = () => undefined,
    label = '',
    checked = false,
    ...props
}) {
    return (
        <span className={styles.customCheckbox}>
            <input
                type="checkbox"
                id={props.id}
                onChange={onChange}
            />
            <label htmlFor={props.id}>{label}</label>
        </span>
    )
}
