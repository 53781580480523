import React from "react";
import styles from './styles.module.scss';
import { Input } from "../input";
import { FileUpload } from "../file-upload";
import { Loading } from "../loading";
import { sendCareersFormData } from "../../request-service";

export function CareersForm() {
    const defaultFormData = {
        desiredPosition: '',
        workExperience: '',
        skills: '',
        salary: '',
        portfolio: '',
        files: [],
        name: '',
        email: '',
        cityOfResidence: ''
    };

    const [formData, setFormData] = React.useState(defaultFormData);
    const [loading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    async function handleFormSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const uploadFormData = new FormData();
        const { files, ...restFormData } = formData;

        for (const key in restFormData) {
            if (restFormData.hasOwnProperty(key)) {
                uploadFormData.append(key, restFormData[key])
            }
        }

        if (files) {
            files.forEach((file) => {
                uploadFormData.append('file', file);
            });
        }

        try {
            await sendCareersFormData(uploadFormData);

            setFormData(defaultFormData);
            setLoading(false);
            setSuccessMessage('Your career data was successfully sent.');
        } catch (error) {
            setLoading(false);
            setErrorMessage('Something went wrong, please try again later');
        }
    }

    function getFileExtension(name){
        const nameParts = name.split('.');
        if (nameParts.length > 0)
            return nameParts[nameParts.length - 1];
        else
            return '';
    }

    function filterFiles(files){
        var validExt = ['pdf', 'odt', 'ppt', 'pptx', 'key', 'doc', 'docx', 'rtf', 'wpd', 'apk'];
        var res = [];
        for(var i=0;i<files.length;i++){
            var ext = getFileExtension(files[i].name);
            if (validExt.includes(ext)){
                res.push(files[i]);
            }
        }

        return res;
    }
    function handleFormChange({ target }) {
        let value = target.value;

        if (target.files) {
            value = formData.files;
            Array.prototype.push.apply(value, filterFiles(target.files));
            value = value.slice(0, 3);
        }

        setFormData({
            ...formData,
            [target.name]: value
        });
    }

    function handleFileUploadFileRemove(file) {
        const filesStateCopy = [...formData.files];
        const existingIndex = filesStateCopy.indexOf(file);

        if (existingIndex > -1) {
            filesStateCopy.splice(existingIndex, 1);

            setFormData({
                ...formData,
                files: filesStateCopy
            })
        }
    }

    function handleMessageClose() {
        setErrorMessage('');
        setSuccessMessage('');
    }

    return (
        <div className={styles.publishing}>
            <Loading
                isLoading={loading}
                errorMessage={errorMessage}
                successMessage={successMessage}
                onMessageClose={handleMessageClose}
            />

            <form
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
            >
                <div>
                    <Input
                        label="Desired position"
                        value={formData.desiredPosition}
                        name="desiredPosition"
                        id="desiredPosition"
                    />
                    <Input
                        label="Work experience"
                        value={formData.workExperience}
                        name="workExperience"
                        id="workExperience"
                    />
                    <Input
                        label="Skills"
                        value={formData.skills}
                        name="skills"
                        id="skills"
                    />
                    <Input
                        label="Salary"
                        hint="USD/hr"
                        value={formData.salary}
                        name="salary"
                        id="salary"
                        isRequired={false}
                    />
                    <Input
                        label="Portfolio"
                        value={formData.portfolio}
                        name="portfolio"
                        id="portfolio"
                        isTextarea
                    />

                    <div className={styles.formTextBlock}>
                        <p>
                            Send a detailed portfolio. This is a key of our evaluation and
                            selection process, so it's super important to make it high quality and informative.
                            Upload the files in one of the following formats: pdf, odt, ppt, pptx, key, doc, docx, rtf,
                            wpd, apk. You can upload up to 3 files.
                        </p>
                    </div>

                    <FileUpload
                        name='files'
                        files={formData.files}
                        onFileRemove={handleFileUploadFileRemove}
                    />
                </div>

                <div className={styles.rightForm}>
                    <h2 className={styles.formH2}>
                        Contact Details
                    </h2>

                    <Input
                        label="Name"
                        value={formData.name}
                        name="name"
                        id="name"
                    />
                    <Input
                        label="Email"
                        value={formData.email}
                        name="email"
                        id="email"
                        type="email"
                    />
                    <Input
                        label="City of residence"
                        value={formData.cityOfResidence}
                        name="cityOfResidence"
                        id="cityOfResidence"
                    />
                </div>

                <div className={styles.bottom}>
                    <div className="formSubmitButtonErrorsWrap contactFormButton">
                        <input
                            type="submit"
                            className="buttonWrap button button-green contactSubmitButton"
                            value="Send Message"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
