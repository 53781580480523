import React from "react";
import classNames from 'classnames';
import styles from './index.module.scss';
import NoSSR from 'react-no-ssr';

export function Input({
    id = '',
    value = '',
    onChange = () => undefined,
    isRequired = true,
    label = '',
    isTextarea = false,
    hint = '',
    ...props
}) {
    return (
        <div className="formFieldWrap">
            <label
                className="field-title contactNameField"
                htmlFor={id}
            >
                {label}: {isRequired && (<span>(required)</span>)}  
            </label>

            {!isTextarea && (
              <NoSSR>
                <input
                    id={id}
                    type="text"
                    value={value}
                    className={
                        hint ? "contactFieldWithHint requiredField" : "contactField requiredField"
                    }
                    required={isRequired}
                    onChange={onChange}
                    {...props}
                />
                {hint && (<span className="hint"> {hint}</span>)}
              </NoSSR>
            )}

            {isTextarea && (
                <textarea
                    id={id}
                    className={classNames("contactTextarea", "requiredField", styles.textarea)}
                    value={value}
                    required={isRequired}
                    onChange={onChange}
                    {...props}
                />
            )}
        </div>
    )
}



