import React from "react";
import styles from './styles.module.scss';
import classNames from 'classnames';

export const Button = (
    {
        label,
        fullSize = true,
        reject = false,
        isDefault = false,
        isRounded = false,
        className = '',
        ...props
    }
) => {
    return (
        <button
            className={
                classNames(
                    styles.button,
                    { [styles.fullSize]: fullSize },
                    { [styles.reject]: reject },
                    { [styles.default]: isDefault },
                    { [styles.rounded]: isRounded },
                    className
                )
            }
            {...props}
        >
            {label}
        </button>
    )
};
