import React from "react";
import styles from "./styles.module.scss";
import classNames from 'classnames';
import { Button } from "../button";

export function Loading(
    {
        isLoading = false,
        successMessage = "",
        errorMessage = "",
        onMessageClose = () => undefined
    }
) {
    return (
        <div
            className={classNames(
                styles.loading,
                { [styles.active]: isLoading }
            )}
        >
            {successMessage && (
                <div className={
                    classNames(
                        styles.message,
                        styles.success
                    )
                }>
                    {successMessage}

                    <div className={styles.buttons}>
                        <Button
                            label="Close"
                            fullSize={false}
                            onClick={onMessageClose}
                        />
                    </div>
                </div>
            )}

            {errorMessage && (
                <div className={
                    classNames(
                        styles.message,
                        styles.error
                    )
                }>
                    {errorMessage}

                    <div className={styles.buttons}>
                        <Button
                            label="Close"
                            fullSize={false}
                            reject={true}
                            onClick={onMessageClose}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
