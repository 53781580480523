import React from "react";
import styles from './styles.module.scss';
import { Input } from "../input";
import { Selector } from "../selector";
import { FileUpload } from "../file-upload";
import { Loading } from "../loading";
import { sendPartnershipFormData } from "../../request-service";

export function PartnershipForm() {
    const defaultFormData = {
        massageType: '',
        name: '',
        email: '',
        message: '',
        files: []
    };

    const [formData, setFormData] = React.useState(defaultFormData);

    const [loading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    async function handleFormSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const uploadFormData = new FormData();
        const { files, ...restFormData } = formData;

        for (const key in restFormData) {
            if (restFormData.hasOwnProperty(key)) {
                uploadFormData.append(key, restFormData[key])
            }
        }

        if (files) {
            files.forEach((file) => {
                uploadFormData.append('file', file);
            });
        }

        try {
            await sendPartnershipFormData(uploadFormData);

            setFormData(defaultFormData);
            setLoading(false);
            setSuccessMessage('Your partnership data was successfully sent.');
        } catch (error) {
            setLoading(false);
            setErrorMessage('Something went wrong, please try again later');
        }
    }

    function getFileExtension(name){
        const nameParts = name.split('.');
        if (nameParts.length > 0)
            return nameParts[nameParts.length - 1];
        else
            return '';
    }

    function filterFiles(files){
        var validExt = ['pdf', 'odt', 'ppt', 'pptx', 'key', 'doc', 'docx', 'rtf', 'wpd', 'apk'];
        var res = [];
        for(var i=0;i<files.length;i++){
            var ext = getFileExtension(files[i].name);
            if (validExt.includes(ext)){
                res.push(files[i]);
            }
        }

        return res;
    }
    function handleFormChange({ target }) {
        let value = target.value;

        if (target.files) {
            value = formData.files;
            Array.prototype.push.apply(value, filterFiles(target.files));
            value = value.slice(0, 3);
        } 

        setFormData({
            ...formData,
            [target.name]: value
        });
    }

    function handleFormSelector(data, event) {
        setFormData({
            ...formData,
            [event.name]: data.value
        });
    }

    function handleFileUploadFileRemove(file) {
      const filesStateCopy = [...formData.files];
      const existingIndex = filesStateCopy.indexOf(file);

      if (existingIndex > -1) {
        filesStateCopy.splice(existingIndex, 1);

        setFormData({
            ...formData,
          files: filesStateCopy
        });
      }
    }

    function handleMessageClose() {
        setErrorMessage('');
        setSuccessMessage('');
    }

    return (
        <div className={styles.partnershipForm}>
            <Loading
                isLoading={loading}
                errorMessage={errorMessage}
                successMessage={successMessage}
                onMessageClose={handleMessageClose}
            />

            <form
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
            >

                <div className={styles.separated}>
                    <div>
                        <Input
                            label="Name"
                            value={formData.name}
                            name="name"
                            id="name"
                        />
                    </div>
                    <div>

                        <Input
                            label="Email"
                            value={formData.email}
                            name="email"
                            id="email"
                            type="email"
                        />

                    </div>
                </div>

                <Input
                    label="Message"
                    value={formData.message}
                    name="message"
                    id="message"
                    isTextarea
                />

                <div className={styles.formTextBlock}>
                  <h3>Presentation and documentation</h3>
                  <p>
                    Send a detailed presentation about your project. This is a key of our evaluation and
                    selection process, so it's super important to make it high quality and informative.
                    Upload the files in one of the following formats: pdf, odt, ppt, pptx, key, doc, docx, rtf, wpd, apk. You can upload up to 3 files.
                  </p>
                </div>

                <FileUpload
                    name='files'
                    files={formData.files}
                    onFileRemove={handleFileUploadFileRemove}
                    required
                />

                <div className={styles.bottom}>
                    <div className="formSubmitButtonErrorsWrap contactFormButton">
                        <input
                            type="submit"
                            className="buttonWrap button button-green contactSubmitButton"
                            value="Send Message"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
